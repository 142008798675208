import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";
import AppBar from "./components/AppBar";
import Leggings from "./components/Products/Leggings";
import Sportbra from "./components/Products/Sportbra";
import ProductDetail from "./components/ProductDetail";
import StickyWhatsApp from "./components/StickyWhatsApp";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <AppBar />
          <StickyWhatsApp />
          <Route exact path="/" component={Home} />
          <Route exact path="/leggings" component={Leggings} />
          <Route exact path="/sportbra" component={Sportbra} />
          <Route path="/leggings/detalle" component={ProductDetail} />
          <Route path="/sportbra/detalle" component={ProductDetail} />
        </div>
      </Router>
    );
  }
}

export default App;
