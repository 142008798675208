import React, { Component } from 'react';
import PageHeader from './PageHeader';
import PageBody from './PageBody';
import Suscribe from './Suscribe';
import Footer from './Footer';

export default class index extends Component {
    render() {
        return (
            <div>
                <PageHeader/>   
                <PageBody/>   
                <Suscribe/>   
                <Footer/>      
            </div>
        )
    }
}
