import React, { Component } from "react";
import CharacteristicsDetail from "../../CharacteristicsDetail";
import Col from "react-bootstrap/Col";
import "./PageBody.css";
import Row from "react-bootstrap/Row";

export default class index extends Component {
  render() {
    return (
      <div className="divCharacteristics">
        <Row  >
          <Col>            
            <CharacteristicsDetail
              description="Investigación anatómica para adaptación y ajuste"
              componentColor="#63D9F8"
              icon="faRunning"
              iconColor="#4333D2"
            />            
          </Col>
          <Col>
            <CharacteristicsDetail
              description="Tela con compresión y la mejor elongación en el mercado"
              componentColor="#449DED"
              icon="faConfluence"
              iconColor="#4333D2"
            />
          </Col>
          <Col>
            <CharacteristicsDetail
              description="Fibras con la mejor tecnología para mayor rendimiento físico"
              componentColor="#2C6CE5"
              icon="faSchlix"
              iconColor="#4333D2"
            />
          </Col>
          <Col>
            <CharacteristicsDetail
              description="Fibras especiales que reducen el riesgo de sobrecalentamiento del cuerpo"
              componentColor="#1533DA"
              icon="faWind"
              iconColor="#4333D2"
            />
          </Col>
        </Row>
      </div>
    );
  }
}
