import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageSliderDetail.css";

export default class index extends Component {
  render() {
    
    const images = this.props.urlImagenes.map(url => {
      return {
        original: url,
        thumbnail: url
      }
    });

    return (
      <div className="imageSliderDetail">
        {this.props.urlImagenes.length > 0 && (
          <ImageGallery 
            items={images} 
            thumbnailPosition = "left"
            showPlayButton = {false}
            showFullscreenButton = {false}
            showBullets = {true}/>
        )}
      </div>
    );
  }
}
