import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../Firebase";
import ImageSliderDetail from "../ImageSliderDetail";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rater from "react-rater";
import Footer from "../Home/Footer";
import "./ProductDetail.css";

export default class index extends Component {
  state = {
    urlImagenes: []
  };

  render() {
    let precioDescuento;

    if (this.props.location.producto === undefined) {
      return <Redirect to="/" />;
    }

    if (this.props.location.producto.descuento > 0) {
      precioDescuento = (
        <div className="resumen-precio ">
          <div className="descuento">
            {this.props.location.producto.descuento}%
          </div>
          <div className="precio">${this.props.location.producto.precio}</div>
        </div>
      );
    }

    if (this.state.urlImagenes.length > 0) {
      return (
        <div>
          <Container>
            <Row className="justify-content-md-center">
              <div className="ProductDetail">
                <Col sm={6} lg={true} className="col1-productDetail">
                  <div className={`ImageSliderDetail ${this.props.location.producto.tipo}detail`}>
                    <ImageSliderDetail urlImagenes={this.state.urlImagenes} />
                  </div>
                </Col>
                <Col sm={6} lg={true} className="col2-productDetail">
                  <div className="productDescription">
                    <h1>{this.props.location.producto.nombre}</h1>
                    <Rater
                      rating={this.props.location.producto.estrellas}
                      total={5}
                      interactive={false}
                    />
                    <hr />
                    <div className="codigoLabel">
                      <h5> Código: </h5>
                      <div className="codigoValor">
                        {this.props.location.producto.id}
                      </div>
                    </div>

                    <div className="tallaLabel">
                      <h5>Talla: </h5>
                      <div className="tallaValor">
                        {this.props.location.producto.tallas
                          .split(",")
                          .map((talla, i) => {
                            return (
                              <div key={i} className="tallaIndividual">
                                {talla}
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <hr />
                    <div className="resumen-precio">
                      <div className="precio-final">
                        ${this.props.location.producto.precioFinal}
                      </div>
                      {precioDescuento}
                    </div>
                    <h3>{this.props.location.producto.descripcion}</h3>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
          <Footer />
        </div>
      );
    }
    return <div>Loading ...</div>;
  }

  
  

  componentDidMount() {
    this.loadImagesFromDataStorageById();
  }

  loadImagesFromDataStorageById() {
    if (this.props.location.producto === undefined) {
      return <Redirect to="/" />;
    }
    this.UpdateUrlImages(this.props.location.producto.imgUrl);
    firebase
      .storage()
      .ref()
      .child(this.props.location.producto.tipo)
      .child(this.props.location.producto.id)
      .listAll()
      .then(res => {
        res.items.forEach(itemRef => {
          // All the items under listRef.
          itemRef
            .getDownloadURL()
            .then(url => {
              // Update state with url image.
              this.UpdateUrlImages(url);
            })
            .catch(function(error) {
              console.log("error al obtener la url de descarga");
            });
        });
      })
      .catch(function(error) {
        // A full list of error codes is available at
        console.log("ha ocurrido un error al listar las imagenes.");
      });
  }

  UpdateUrlImages(url) {
    this.setState(function(state) {
      state.urlImagenes.push(url);
      return {
        urlImagenes: state.urlImagenes
      };
    });
  }
}
