import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Rater from "react-rater";
import "./Products.css";
import "react-rater/lib/react-rater.css";

export default class index extends Component {
  state = { imgUrl: "" };
  render() {
    let precioDescuento;

    if (this.props.descuento > 0) {
      precioDescuento = (
        <div className="resumen-precio ">
          <div className="descuento">{this.props.descuento}%</div>
          <div className="precio">${this.props.precio}</div>
        </div>
      );
    }

    return (
      <div className="products">
        <div className="imagen-detalle">
          <img src={this.state.imgUrl} alt={this.props.nombre} />

          <Nav >
            <Nav.Item>
              <NavLink
                to={{
                  pathname: `/${this.props.tipo}/detalle`,
                  producto: {
                    nombre: this.props.nombre,
                    id: this.props.id,
                    precio: this.props.precio,
                    descuento: this.props.descuento,
                    estrellas: this.props.estrellas,
                    descripcion: this.props.descripcion,
                    precioFinal: this.precioFinal,
                    precioDescuento: this.precioDescuento,
                    tallas: this.props.tallas,
                    imgUrl: this.state.imgUrl,
                    tipo: this.props.tipo
                  }
                }}
              >
                <Button className="boton-ver" variant="outline-dark">Ver</Button>
              </NavLink>
            </Nav.Item>
          </Nav>
        </div>
        <h2>{this.props.nombre + " " + this.props.id}</h2>
        <div className="resumen-precio">
          <div className="precio-final">${this.precioFinal}</div>
          {precioDescuento}
        </div>
        <Rater rating={this.props.estrellas} total={5} interactive={false} />
      </div>
    );
  }

  componentDidMount() {
    this.precioFinal =
      this.props.precio - (this.props.precio * this.props.descuento) / 100;
      this.setState({ imgUrl: `${process.env.PUBLIC_URL}/assets/${this.props.tipo}/Main/${this.props.id}.png` });
  }
  
}
