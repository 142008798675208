import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import firebase from "../../Firebase";
import "./Suscribe.css";

const mensajeToast = {
  Existente: "Ya te encuentras registrado",
  Creado:
    "Gracias por suscribirte !!! ya haces parte de nuestra lista de clientes preferenciales",
    Error: "No es posible suscribirse en este momento, por favor intenta mas tarde",
    Error2: "Por favor ingresa un correo válido"
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.fromRef = React.createRef();
    this.btnRef = React.createRef();

  }

  state = { email: "", show: false, mensajeActualToast: "" };

  render() {
    return (
      <Form
        onSubmit={this.onFormSubmit}
        ref={this.fromRef}
        className="suscribeForm"
      >
        <h1>Suscríbete y recibe novedades además de promociones exclusivas</h1>
        <Form.Row>
          <Form.Control
            onChange={this.onInputChange}
            value={this.state.email}
            type="email"
            placeholder="Email"
          />
          <Button ref={this.btnRef}  type="submit">Suscribirse</Button>
        </Form.Row>

        <Form.Row className="rowToast">
          <Toast
            onClose={() => this.setState({ show: false })}
            show={this.state.show}
            delay={5000}
            autohide
          >
            <Toast.Body>{this.state.mensajeActualToast}</Toast.Body>
          </Toast>
        </Form.Row>
      </Form>
    );
  }

  onInputChange = event => {
    this.setState({ email: event.target.value });
    this.btnRef.current.removeAttribute("disabled");
  };

  onFormSubmit = event => {
    
    event.preventDefault();

    if(this.state.email === ""){
      this.setState({        
        show: true,
        mensajeActualToast: mensajeToast.Error2
      });
      return;
    }

    if(this.fromRef.current[2] === undefined)
    {
      this.setState({        
        show: true,
        mensajeActualToast: mensajeToast.Error
      });
      return;
    }
    this.btnRef.current.setAttribute("disabled", "disabled");
    if (this.fromRef.current[2].value === "valid") {
      this.suscribirse();
    }
  };

  suscribirse = async () => {    
    var suscribirseFuncion = firebase.functions().httpsCallable("suscribirse");
    suscribirseFuncion({ email: this.state.email })
      .then((result) => {
        // Read result of the Cloud Function.
        if (result.data.resultado) {
          this.setState({
            email: "",
            show: true,
            mensajeActualToast: mensajeToast.Creado
          });
          this.btnRef.current.removeAttribute("disabled");
        } else {
          this.setState({
            show: true,
            mensajeActualToast: mensajeToast.Existente
          });
          this.btnRef.current.removeAttribute("disabled");
        }
      })
      .catch((error) =>  {
        // Getting the Error details.
        this.setState({
          show: true,
          mensajeActualToast: mensajeToast.Error
        });
        console.log("error al llamar la funcion:", error);
      });
  };
}
