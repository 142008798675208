import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const  firebaseConfig = {
    apiKey: "AIzaSyDrkZ-v0ASTO77Y2e4pZYmChzWHJQYrspc",
    authDomain: "bluefit-d09bb.firebaseapp.com",
    databaseURL: "https://bluefit-d09bb.firebaseio.com",
    projectId: "bluefit-d09bb",
    storageBucket: "bluefit-d09bb.appspot.com",
    messagingSenderId: "1014324378792",
    appId: "1:1014324378792:web:7646cf8893d6b2e513d69d",
    measurementId: "G-VHTVD54Q12"
};

firebase.initializeApp(firebaseConfig);


export default firebase;