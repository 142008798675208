import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import './PageHeader.css'
import MainSlider1 from "../../../assets/home/main-slider-1.png";
import MainSlider2 from "../../../assets/home/main-slider-2.png";
import MainSlider3 from "../../../assets/home/main-slider-3.png";
import MainSlider4 from "../../../assets/home/main-slider-4.png";
import MainSlider5 from "../../../assets/home/main-slider-5.png";

export default class index extends Component {
  render() {
 
    return (      
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={MainSlider1}
            alt="slide 1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={MainSlider2}
            alt="slide 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={MainSlider3}
            alt="slide 3"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={MainSlider4}
            alt="slide 4"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={MainSlider5}
            alt="slide 5"
          />
        </Carousel.Item>
      </Carousel>
    );
  }
}
