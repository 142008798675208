import React, { Component } from "react";
import WhatsAppIcon from "../../assets/home/whatsappicon.png";
import "./StickyWhatsApp.css";

//https://api.whatsapp.com/send?phone=573147384279&text=&source=BlueFit&data=
export default class index extends Component {
  render() {
    return (
      <div className="StickyWhatsApp fixed-bottom text-right">
        <a href='https://api.whatsapp.com/send?phone=573147384279&text=&source=BlueFit&data='>
          <img src={WhatsAppIcon} alt="whatsapp icon"/>
        </a>
      </div>
    );
  }
}
