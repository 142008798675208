import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faFlickr,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

export default class index extends Component {
  render() {
    return (
      <Col className="footer">        
        <Row className="callUs">
          <Col>
          <FontAwesomeIcon
              icon={faFacebookSquare}
              size="5x"
              color="#f3faff"
            />
            <FontAwesomeIcon icon={faFlickr} size="5x" color="#f3faff" />
          </Col>
          <Col>
          <h2>
              Comuníquese con nosotros <br />
              Blue - Fit <br />
              <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#74C76B" />
              WhatsApp : +57 313 516 4847 <br />
              contacto@blue-fit.co <br />
            </h2>
            
          </Col>
        </Row>
        <hr className="secondHr" />
        <Row className="trademark">© Blue Fit 2019</Row>
      </Col>
    );
  }
}
