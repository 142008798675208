import React, { Component } from "react";
import firebase from "../../Firebase";
import Products from "../index";
import Footer from "../../Home/Footer";
import "./Sportbra.css";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productos: []
    };
  }

  render() {
    return (
      <div>
        <div className="sportbra row align-items-center justify-content-center">
          {this.state.productos.map((valor, index) => {
            return (
              <Products
                key={index}
                nombre={valor.nombre}
                id={valor.id}
                precio={valor.precio}
                descuento={valor.descuento}
                estrellas={valor.estrellas}
                descripcion={valor.descripcion}
                tallas={valor.tallas}
                tipo={valor.tipo}
              />
            );
          })}
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    this.loadDataFromDataBase();
  }

  loadDataFromDataBase() {
    firebase
      .firestore()
      .collection("producto")
      .where("tipo", "==", "sportbra")
      .where("tallas", ">", '')
      .get()
      .then(querySnapshot => {
        let productosDB = [];
        let tepmProduct;
        querySnapshot.forEach(producto => {
          tepmProduct = producto.data();
          tepmProduct.id = producto.id;
          productosDB.push(tepmProduct);
        });
        this.setState({ productos: productosDB });
      });
  }
}
