import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppLogo from "../../assets/home/logo.png";
import "./AppBar.css";

export default class index extends Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        sticky="top"
        className="bgg-dark"
        variant="light"
        expand="lg"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="site-header sticky-top py-1">
            <Navbar.Brand href="/">
              <img
                src= {AppLogo}
                width="140"
                height="50"
                className="d-inline-block align-top"
                alt="blue-fit logo"
              />
            </Navbar.Brand>

            <div className="container d-flex flex-column flex-md-row justify-content-between">
              <Nav.Item>
                <NavLink
                  exact
                  className="py-2 d-lg-inline-block space-between"
                  to="/"
                >
                  Inicio
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  className="py-2 d-lg-inline-block space-between"
                  to="/leggings"
                >
                  Leggings
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  className="py-2 d-lg-inline-block space-between"
                  to="/sportbra"
                >
                  Sport Bra
                </NavLink>
              </Nav.Item>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
