import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning, faWind } from "@fortawesome/free-solid-svg-icons";
import { faConfluence, faSchlix } from "@fortawesome/free-brands-svg-icons";
import "./CharacteristicsDetail.css";

export default class index extends Component {
  render() {
    const colorStyle = {
      background: this.props.componentColor
    };
    
    return (      
      <div>
        <div className="large-content" style={colorStyle}>
          <div className="circle-icon">
            <FontAwesomeIcon
              icon={this.getIconComponent()}
              size="2x"
              color={this.props.iconColor}
            />
          </div>
          <div className="component-description">
            <h4> {this.props.description}</h4>
          </div>
        </div>
      </div>
    );
  }

  getIconComponent() {
    switch (this.props.icon) {
      case "faWind":
        return faWind;
      case "faRunning":
        return faRunning;
      case "faConfluence":
        return faConfluence;
      case "faSchlix":
        return faSchlix;
      default:
        return "";
    }
  }
}
